import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import { Form } from "react-form";

import withStyles from "@mui/styles/withStyles";
import saveAs from "file-saver";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import { Table, TableBody, TableRow, Alert } from "@mui/material";
import Typography from "../common/TypographyWrapper";
import CircularProgress from "@mui/material/CircularProgress";

import ProjectFacts from "./components/ProjectFacts";
import AppContainer from "../common/AppContainer";
import CheckboxGroup from "../common/CheckboxGroup";
import Checkbox from "../common/Checkbox";
import HelpDialog from "../common/HelpDialog";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import Select from "../common/Select";
import { HAS_N_BALANCE, MAKE_OPTIONS } from "../../api/constants";
import * as authActions from "../auth/actions";

import { connect } from "react-redux";
import { Project } from "./models";

import { reloadAll } from "../common/orm";
import { dbFetch } from "../../api/fetch";

const getProject = Project.selectByUrlId();

// CSS in JS styles
const styles = (theme) => ({
    buttonWidth: {
        minWidth: "44px",
        width: "44px",
    },
    centerAlign: {
        textAlign: "center",
    },
    crumbColor: {
        color: theme.palette.primary.main,
    },
    linkColor: {
        color: "#808080",
    },
    progress: {
        color: "#4CAF50",
        position: "absolute",
        marginTop: 10,
        marginLeft: -85,
    },
});

const columnData = [
    { id: "crop_label", numeric: false, label: "Crop", allowSort: false, width: "10%" },
    { id: "project_yield", numeric: false, label: "Yield (MT/Ha)", allowSort: false, width: "10%" },
    { id: "project_ghg", numeric: true, label: "GHG Emissions kg (CO2e/MT)", allowSort: false, width: "20%" },
    { id: "project_soil_erosion", numeric: true, label: "Soil Erosion (MT/Ha/Yr)", allowSort: false, width: "15%" },
    {
        id: "project_irrigation_rate",
        numeric: true,
        label: "Irrigated Water Use (CM/T)",
        allowSort: false,
        width: "15%",
    },
    { id: "enrolled_area", numeric: true, label: "Enrolled Area (Ha)", allowSort: false, width: "15%" },
    { id: "total_production", numeric: true, label: "Total Production (MT)", allowSort: false, width: "15%" },
];

class TscTable extends Component {
    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, data } = this.props;

        return (
            <Table>
                <EnhancedTableHead onRequestSort={this.handleRequestSort} columnData={columnData} />
                <TableBody>
                    {data.map((a) => (
                        <TableRow hover>
                            <CustomTableCell>{a.crop_label}</CustomTableCell>
                            <CustomTableCell>{a.project_yield.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.project_ghg.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.project_soil_erosion.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.project_irrigation_rate}</CustomTableCell>
                            <CustomTableCell>{a.enrolled_area.toFixed(2)}</CustomTableCell>
                            <CustomTableCell>{a.total_production.toFixed(2)}</CustomTableCell>
                        </TableRow>
                    ))}
                    {data.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                Insufficiant Data - More than one grower is needed to calculate project averages
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

const columnData2 = [
    {
        id: "total_growers",
        center: true,
        numeric: true,
        label: "Total Growers with Completed Questionnaires",
        allowSort: false,
        width: "40%",
    },
    { id: "gold", center: true, numeric: false, label: "Total Gold (%)", allowSort: false, width: "15%" },
    { id: "silver", center: true, numeric: false, label: "Total Silver (%)", allowSort: false, width: "15%" },
    { id: "bronze", center: true, numeric: false, label: "Total Bronze (%)", allowSort: false, width: "15%" },
    { id: "notmet", center: true, numeric: false, label: "Equivalency not met (%)", allowSort: false, width: "15%" },
];

class SaiTable extends Component {
    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, data } = this.props;

        return (
            <Table>
                <EnhancedTableHead columnData={columnData2} />
                <TableBody>
                    {data.map((a) => (
                        <TableRow hover>
                            <CustomTableCell className={classes.centerAlign}>{a.total_growers}</CustomTableCell>
                            <CustomTableCell className={classes.centerAlign}>
                                {a.gold} ({a.gold_percent}%)
                            </CustomTableCell>
                            <CustomTableCell className={classes.centerAlign}>
                                {a.silver} ({a.silver_percent}%)
                            </CustomTableCell>
                            <CustomTableCell className={classes.centerAlign}>
                                {a.bronze} ({a.bronze_percent}%)
                            </CustomTableCell>
                            <CustomTableCell className={classes.centerAlign}>
                                {a.notmet} ({a.notmet_percent}%)
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    {data.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={5} className={classes.centerAlign}>
                                No Supplemental Questionnaires for Equivalency with SAI Platform FSA have been completed
                                for the selected reporting year.
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class Reports extends Component {
    state = {
        loading: false,
        reportState: null,
        tscYear: null,
        tscResults: null,
        tscui: null,
        saiYear: null,
        saiResults: null,
        saiui: null,
        dialogOpen: false,
        dialogOpen2: false,
        dialogTitle: null,
        dialogText: null,
        selectedProjectBenchmarkGroup: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    async download(values, filetype) {
        var { project } = this.props;
        let response;
        response = await dbFetch("/reports/" + project.id + "/" + values.report_type);
        saveAs(await response.blob(), project.name + filetype);
        this.setState({ loading: false });
    }

    generateReport = (values) => {
        var { project, history, authLogout, reload } = this.props;
        const { tscui, saiui } = this.state;
        this.setState({ loading: true, tscResults: null });

        if (values.report_type === "benchmarks") {
            values["update_only"] = false;
            values["crop_id"] = this.state.selectedProjectBenchmarkGroup.crop_id;
            values["is_irrigated"] = this.state.selectedProjectBenchmarkGroup.irrigated;
        }

        if (values.report_type === "claims") {
            values["update_only"] = true;
            values["crop_id"] = this.state.selectedProjectBenchmarkGroup.crop_id;
            values["year"] = this.state.selectedProjectBenchmarkGroup.year;
            values["is_irrigated"] = this.state.selectedProjectBenchmarkGroup.irrigated;
        }

        if (values.report_type === "tsc") {
            values["report_years"] = [values["tsc_year"].slice(-4)];
            if (tscui) {
                values["ui"] = true;
            }
        }

        if (values.report_type === "sai") {
            values["report_years"] = [values["sai_year"].slice(-4)];
            if (saiui) {
                values["ui"] = true;
            }
        }

        if (values.report_type === "projectpdf") {
            this.download(values, ".pdf");
            return;
        }

        if (values.report_type === "quality") {
            this.download(values, ".xlsx");
            return;
        }

        dbFetch("/reports/" + project.id + "/" + values.report_type, {
            method: "POST",
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                if (values.report_type === "tsc") {
                    e.json().then((data) => {
                        if (tscui) {
                            this.setState({ loading: false, tscResults: data.message });
                        } else {
                            if (data.message.length === 0) {
                                this.setState({
                                    dialogOpen2: true,
                                    loading: false,
                                    tscResults: data.message,
                                    dialogTitle: "TSC Report Request",
                                    dialogText:
                                        "Insufficiant Data - More than one grower is needed to calculate project averages.",
                                });
                            } else {
                                this.setState({
                                    dialogOpen2: true,
                                    loading: false,
                                    tscResults: data.message,
                                    dialogTitle: "TSC Report Request",
                                    dialogText:
                                        "Your TSC Report is being submitted to the queue. When ready, an email with a link to download the report in Microsoft Excel format will be sent. This can take up to an hour, depending on the number of crop years included. Please check your spam folder if you do not receive the email.",
                                });
                            }
                        }
                    });
                } else if (values.report_type === "sai") {
                    e.json().then((data) => {
                        if (saiui) {
                            this.setState({ loading: false, saiResults: data.message });
                        } else {
                            if (data.message.length === 0) {
                                this.setState({
                                    dialogOpen2: true,
                                    loading: false,
                                    saiResults: data.message,
                                    dialogTitle: "SAI Platform's FSA Equivalency Report Request",
                                    dialogText:
                                        "No Supplemental Questionnaires for Equivalency with SAI Platform FSA have been completed for the selected reporting year.",
                                });
                            } else {
                                this.setState({
                                    dialogOpen2: true,
                                    loading: false,
                                    saiResults: data.message,
                                    dialogTitle: "SAI Platform's FSA Equivalency Report Request",
                                    dialogText:
                                        "Your FSA Equivalency Report is being submitted to the queue. When ready, an email with a link to download the report in Microsoft Excel format will be sent. This can take up to an hour, depending on the number of crop years included. Please check your spam folder if you do not receive the email.",
                                });
                            }
                        }
                    });
                } else {
                    this.setState({ loading: false, dialogOpen: true });
                    if (values.report_type === "benchmarks") {
                        reload();
                    }
                }
            }
        });
    };

    render() {
        var { classes, project, authState } = this.props;
        const {
            loading,
            reportState,
            dialogOpen,
            dialogOpen2,
            selectedProjectBenchmarkGroup,
            tscYear,
            tscResults,
            saiYear,
            saiResults,
            dialogTitle,
            dialogText,
        } = this.state;

        function getPossibleProjectBenchmarkGroups(p) {
            const unavailableReasons = [],
                possible_benchmarks = [],
                crop_ids = [],
                crops = [];

            if (!p.project_data || p.project_data.length === 0) {
                return {
                    groups: [],
                    unavailableReasons: ["No project data available"],
                };
            }

            var cropyears_final_metrics = p.project_data.filter((cy) => cy.include_in_reports);
            if (cropyears_final_metrics.length === 0) {
                return {
                    groups: [],
                    unavailableReasons: [
                        `${p.project_data.length} crop years available, but none are finalized with latest metrics.`,
                    ],
                };
            } else if (cropyears_final_metrics.length < p.project_data.length) {
                unavailableReasons.push(
                    `${p.project_data.length - cropyears_final_metrics.length} crop years not finalized with latest metrics.`,
                );
            }

            cropyears_final_metrics.forEach(function (d) {
                if (crop_ids.indexOf(d.crop_id) === -1) {
                    crop_ids.push(d.crop_id);
                    crops.push({ id: d.crop_id, label: d.crop_label });
                }
            });

            crops.forEach(function (crop) {
                var cropyears_final_metrics_crop = cropyears_final_metrics.filter((d) => {
                    return d.crop_id === crop.id;
                });

                const anyCropBenchmarks = [true, false]
                    .map(function (irrigated) {
                        var cropyears = cropyears_final_metrics_crop.filter((c) => {
                            return c.is_irrigated === irrigated;
                        });

                        var crop_user_ids = [];
                        cropyears.forEach(function (cy) {
                            if (crop_user_ids.indexOf(cy.crop_user_id) === -1) {
                                crop_user_ids.push(cy.crop_user_id);
                            }
                        });

                        if (crop_user_ids.length >= 10) {
                            const year = Math.max(...cropyears.map((cy) => cy.year)).toString(),
                                value = [year, irrigated ? "irrigated" : "rainfed", crop.label].join(" ");
                            possible_benchmarks.push({
                                year: year,
                                irrigated: irrigated,
                                crop_id: crop.id,
                                crop_label: crop.label,
                                id: value,
                                value: value,
                                key: value,
                                label: value,
                            });
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .some(Boolean);
                if (!anyCropBenchmarks) {
                    unavailableReasons.push(`Not enough unique growers for ${crop.label} to generate benchmarks.`);
                }
            });
            return {
                groups: possible_benchmarks,
                unavailableReasons,
            };
        }

        const possibleProjectBenchmarkGroups = project ? getPossibleProjectBenchmarkGroups(project) : {};

        if (!project || project.name === "Not Found") return "Not Found";

        var report_types = [];
        var isProjectAdmin = authState.user.access_user_info || false;
        var isProjectSponser = authState.user.access_user_info || false;

        project.contacts.forEach(function (pp) {
            if (authState.user.id === pp.user_id && (pp.contact_role === "PA" || pp.contact_role === "PP"))
                isProjectAdmin = true;
            else if (authState.user.id === pp.user_id && pp.contact_role === "PS") isProjectSponser = true;
        });

        if (isProjectAdmin) {
            report_types = [
                {
                    optgroup: true,
                    label: "Fieldprint Platform Reports",
                    items: [
                        { iLabel: "Comprensive Data Output File", iValue: "full" },
                        { iLabel: "Fieldprint Project Report", iValue: "html" },
                        { iLabel: "Data Quality Control Check", iValue: "quality" },
                        { iLabel: "Participation Data Output File", iValue: "participation" },
                    ],
                },
                {
                    optgroup: true,
                    label: "Partner Supplemental Reports",
                    items: [
                        project.has_n_balance && { iLabel: "N Balance", iValue: "nbalance" },
                        { iLabel: "SAI Platform's FSA Alignment", iValue: "sai" },
                        { iLabel: "TSC Reporting", iValue: "tsc" },
                    ].filter(Boolean),
                },
                {
                    optgroup: true,
                    label: "Additional Fieldprint Platform Reports",
                    items: [
                        { iLabel: "Project Benchmarks", iValue: "benchmarks" },
                        { iLabel: "Project Fields Shapefile", iValue: "shapefile" },
                        { iLabel: "Project Overview Report", iValue: "overview" },
                        { iLabel: "Project Stats Report", iValue: "stats" },
                    ],
                },
                //  #####  HIDE FOR NOW  #####
                // {
                //     label: "Measurement Claims",
                //     value: "claims"
                // }
            ].filter(Boolean);
        } else if (isProjectSponser) {
            report_types = [
                { label: "Project Stats Report", value: "stats" },
                { label: "Project Overview Report", value: "overview" },
            ];
        }

        function makeYearOptions(years) {
            years = years.map((row) => ({
                label: row,
                value: row,
                key: row,
            }));
            return years;
        }

        var crops = [];
        var years = [];
        var tsc_years = [];
        var sai_years = [];
        project.project_data.forEach(function (cY) {
            if (reportState === "nbalance" && !HAS_N_BALANCE(cY, { project_has_n_balance: true })) {
                return;
            }
            if (crops.filter((f) => f.id === cY.crop_id.toString()).length === 0) {
                crops.push({ name: cY.crop_label, id: cY.crop_id.toString() });
            }
            if (!years.includes(cY.year)) {
                years.push(cY.year);
                tsc_years.push("TSC Reporting for " + cY.year);
                sai_years.push("SAI Platform's FSA Equivalency for " + cY.year);
            }
        });
        crops.sort((a, b) => (a["name"] < b["name"] ? -1 : 1));
        years.sort();
        tsc_years.sort();
        sai_years.sort();

        function getRequestButtonDisabled(group) {
            if (loading) {
                return true;
            } else if (reportState && reportState === "benchmarks" && group) {
                return false;
            } else if (reportState && reportState === "benchmarks" && !group) {
                return true;
            } else if (reportState && reportState === "claims" && group) {
                return false;
            } else if (reportState && reportState === "claims" && !group) {
                return true;
            } else if (reportState) {
                return false;
            } else {
                return true;
            }
        }

        var currentDate = new Date();

        const requestButtonDisabled = getRequestButtonDisabled(selectedProjectBenchmarkGroup);

        return (
            <AppContainer
                authenticated
                color="project"
                title={project.name}
                pageTitle={`Reports for ${project.name}`}
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/projectadmin"}>
                            Project Admin Dashboard
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <Link className={classes.linkColor} to={"/projectadmin/" + project.id}>
                            {project.name}
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp; <span className={classes.crumbColor}>Reports</span>
                    </div>
                }
            >
                <Grid container spacing={24}>
                    <ProjectFacts project={project} />
                    <Grid item xs={12}>
                        <Form
                            key={project.id}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            onSubmit={this.generateReport}
                        >
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="display3" gutterBottom>
                                                Reports
                                            </Typography>
                                            <Typography gutterBottom>
                                                Unless otherwise specified, reports only include crop years that are
                                                finalized and calculated with the latest metrics version.
                                            </Typography>
                                            <Select
                                                eventHandle={(value) =>
                                                    this.setState({
                                                        reportState: value,
                                                        tscResults: null,
                                                        saiResults: null,
                                                    })
                                                }
                                                field="report_type"
                                                label="Select from the list of available reports."
                                                className={classes.standardMargin}
                                                options={report_types}
                                                style={{ width: 300 }}
                                                margin="normal"
                                            />
                                        </Grid>
                                        {reportState && REPORT_DESCRIPTION[reportState] && (
                                            <Grid item xs={12} sx={{ "& a": { color: "#808080" } }}>
                                                {REPORT_DESCRIPTION[reportState]}
                                            </Grid>
                                        )}
                                        {reportState === "tsc" && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Select
                                                        eventHandle={(value) => this.setState({ tscYear: value })}
                                                        field="tsc_year"
                                                        label="Select from available reporting years."
                                                        className={classes.standardMargin}
                                                        options={makeYearOptions(tsc_years)}
                                                        style={{ width: 300 }}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                {tscYear && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="raised"
                                                            type="submit"
                                                            color="primary"
                                                            style={{ marginRight: 32 }}
                                                            onClick={() => this.setState({ tscui: true })}
                                                            disabled={requestButtonDisabled}
                                                        >
                                                            Display Results
                                                        </Button>
                                                        <Button
                                                            variant="raised"
                                                            type="submit"
                                                            color="primary"
                                                            onClick={() => this.setState({ tscui: false })}
                                                            disabled={requestButtonDisabled}
                                                        >
                                                            Display Results and Email Report
                                                        </Button>
                                                        {loading && (
                                                            <>
                                                                <Typography
                                                                    style={{ display: "inline-block", marginLeft: 16 }}
                                                                >
                                                                    Loading... Please wait
                                                                </Typography>
                                                                <CircularProgress
                                                                    size={24}
                                                                    style={{ marginLeft: 8 }}
                                                                    className={classes.progress}
                                                                />
                                                            </>
                                                        )}
                                                    </Grid>
                                                )}
                                                {tscResults && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="title" style={{ display: "inline" }}>
                                                                {tscYear}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                variant="caption"
                                                                style={{ display: "inline" }}
                                                                className={classes.crumbColor}
                                                            >
                                                                Generated with project data as of{" "}
                                                                {currentDate.toLocaleString("en-us", {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                    day: "numeric",
                                                                })}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TscTable
                                                                data={tscResults}
                                                                classes={classes}
                                                                onRef={(el) => (this.tscTable = el)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption">
                                                                Notes:
                                                                <ul>
                                                                    <li>
                                                                        Reporting includes all finalized fields for the
                                                                        crop and reporting year (e.g. 2018) in the
                                                                        Platform on the date the report is generated.
                                                                    </li>
                                                                    <li>
                                                                        Users should refer to the Field to Market TSC
                                                                        Guidance documentation and TSC guidelines for
                                                                        proper usage of data.
                                                                    </li>
                                                                </ul>
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {reportState === "sai" && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Select
                                                        eventHandle={(value) => this.setState({ saiYear: value })}
                                                        field="sai_year"
                                                        label="Select from available reporting years."
                                                        className={classes.standardMargin}
                                                        options={makeYearOptions(sai_years)}
                                                        style={{ width: 300 }}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                                {saiYear && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="raised"
                                                            type="submit"
                                                            color="primary"
                                                            style={{ marginRight: 32 }}
                                                            onClick={() => this.setState({ saiui: true })}
                                                            disabled={requestButtonDisabled}
                                                        >
                                                            Display Summary
                                                        </Button>
                                                        <Button
                                                            variant="raised"
                                                            type="submit"
                                                            color="primary"
                                                            onClick={() => this.setState({ saiui: false })}
                                                            disabled={requestButtonDisabled}
                                                        >
                                                            Display Summary and Email Full Report
                                                        </Button>
                                                        {loading && (
                                                            <>
                                                                <Typography
                                                                    style={{ display: "inline-block", marginLeft: 16 }}
                                                                >
                                                                    Loading... Please wait
                                                                </Typography>
                                                                <CircularProgress
                                                                    size={24}
                                                                    style={{ marginLeft: 8 }}
                                                                    className={classes.progress}
                                                                />
                                                            </>
                                                        )}
                                                    </Grid>
                                                )}
                                                {saiResults && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="title" style={{ display: "inline" }}>
                                                                {saiYear}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                variant="caption"
                                                                style={{ display: "inline" }}
                                                                className={classes.crumbColor}
                                                            >
                                                                Generated with project data as of{" "}
                                                                {currentDate.toLocaleString("en-us", {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                    day: "numeric",
                                                                })}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SaiTable
                                                                data={saiResults}
                                                                classes={classes}
                                                                onRef={(el) => (this.saiTable = el)}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {reportState &&
                                            reportState !== "tsc" &&
                                            reportState !== "quality" &&
                                            reportState !== "sai" &&
                                            reportState !== "projectpdf" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="display3">Filters</Typography>
                                                    </Grid>

                                                    {reportState === "benchmarks" && (
                                                        <Grid item xs={12}>
                                                            {possibleProjectBenchmarkGroups.unavailableReasons.map(
                                                                (reason) => (
                                                                    <Alert severity="warning">{reason}</Alert>
                                                                ),
                                                            )}
                                                            {possibleProjectBenchmarkGroups.groups.length > 0 && (
                                                                <Select
                                                                    eventHandle={(value) =>
                                                                        this.setState({
                                                                            selectedProjectBenchmarkGroup:
                                                                                possibleProjectBenchmarkGroups.groups.filter(
                                                                                    (g) => g.value === value,
                                                                                )[0],
                                                                        })
                                                                    }
                                                                    field="dbenchmark"
                                                                    label="Select from available Project Benchmarks"
                                                                    options={possibleProjectBenchmarkGroups.groups}
                                                                    fullWidth
                                                                    margin="normal"
                                                                />
                                                            )}
                                                        </Grid>
                                                    )}

                                                    {reportState === "claims" && (
                                                        <Grid item xs={12}>
                                                            <Select
                                                                eventHandle={(value) =>
                                                                    this.setState({
                                                                        selectedProjectBenchmarkGroup:
                                                                            possibleProjectBenchmarkGroups.groups.filter(
                                                                                (g) => g.value === value,
                                                                            )[0],
                                                                    })
                                                                }
                                                                field="dbenchmark"
                                                                label="Select from available Measurement Claims"
                                                                options={possibleProjectBenchmarkGroups.groups}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                    )}

                                                    {reportState &&
                                                        reportState !== "overview" &&
                                                        reportState !== "benchmarks" &&
                                                        reportState !== "claims" && (
                                                            <Grid item xs={12}>
                                                                <CheckboxGroup
                                                                    field="report_crops"
                                                                    label="Crops: Select one or more crops."
                                                                    row={true}
                                                                    options={MAKE_OPTIONS(crops)}
                                                                />
                                                            </Grid>
                                                        )}

                                                    {reportState &&
                                                        reportState !== "benchmarks" &&
                                                        reportState !== "claims" && (
                                                            <Grid item xs={12}>
                                                                <CheckboxGroup
                                                                    field="report_years"
                                                                    label="Years: Select one or more years available based on crop years associated with project."
                                                                    row={true}
                                                                    options={makeYearOptions(years)}
                                                                />
                                                            </Grid>
                                                        )}

                                                    {reportState === "full" && (
                                                        <Grid item xs={12}>
                                                            {authState.user.access_user_info && (
                                                                <Checkbox
                                                                    field="user_info"
                                                                    label="Include Grower Info in output"
                                                                />
                                                            )}

                                                            <Checkbox
                                                                field="include_old_metrics"
                                                                label="Include crop years with old or uncalculated metrics"
                                                            />
                                                        </Grid>
                                                    )}
                                                </>
                                            )}

                                        {reportState !== "tsc" && reportState !== "sai" && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        {reportState === "html" ? (
                                                            <>
                                                                Once your request is submitted, the system will queue
                                                                the report. The Fieldprint Project Team will begin the
                                                                process of generating your report and email you a link
                                                                with the report within 3-5 business days of the request.
                                                                Please email{" "}
                                                                <a
                                                                    className={classes.linkColor}
                                                                    href="mailto:support@fieldtomarket.org"
                                                                >
                                                                    support@fieldtomarket.org
                                                                </a>{" "}
                                                                if you have any questions or need to check the status of
                                                                your Fieldprint Project Report.
                                                            </>
                                                        ) : (
                                                            <>
                                                                Once your request is submitted, the system will queue
                                                                the report. When processing is complete, the system will
                                                                generate an email with a download link. This can take
                                                                from several minutes to more than an hour, depending on
                                                                the number of fields, crops and years and required
                                                                computation time.
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="raised"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={requestButtonDisabled}
                                                    >
                                                        Request Report
                                                    </Button>
                                                    {loading && (
                                                        <CircularProgress size={24} className={classes.progress} />
                                                    )}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <HelpDialog
                    question="Project Report Request"
                    showErrorOutline={true}
                    text={
                        reportState == "html" ? (
                            <>
                                Your Project report is being submitted to the queue. The Fieldprint Project Team will
                                begin the process of generating your report and email you a link with the report within
                                3-5 business days of the request. Please check your spam folder if you do not receive
                                the email with the report within 3-5 business days or email{" "}
                                <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                    support@fieldtomarket.org
                                </a>
                                . Please email{" "}
                                <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                    support@fieldtomarket.org
                                </a>{" "}
                                if you have any questions or need to check the status of your Fieldprint Project Report.
                            </>
                        ) : (
                            <>
                                Your Project report is being submitted to the queue. When ready, an email with a link to
                                download the report in Microsoft Excel format will be sent. This can take up to an hour,
                                depending on the number of crop years included. Please check your spam folder if you do
                                not receive the email.
                            </>
                        )
                    }
                    open={dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                />
                <HelpDialog
                    question={dialogTitle}
                    showErrorOutline={true}
                    text={dialogText}
                    open={dialogOpen2}
                    onClose={() => this.setState({ dialogOpen2: false })}
                />
            </AppContainer>
        );
    }
}

const REPORT_DESCRIPTION = {
    full: (
        <Typography gutterBottom>
            The Comprehensive Data (CD) Output File is a tabular output of all available data related to the fields
            enrolled in the project, provided as an Excel workbook. Data columns include field size, county location,
            crop grown, yield, each of the 8 sustainability metric scores and components contributing to the scores.
            Additional tabs in this output include Enrolled Acres, Grower Info. Enrolled Acres details grower and farm
            ID, crop year, crop, entered acres (total, irrigated and non-irrigated), managed acres (total, irrigated,
            and non-irrigated), enrolled project acres and indicates if the 10% rule is met (10% of managed acres must
            be entered into the platform to meet the Fieldprint Project Standard). Grower Info details growers ID,
            email, first and last name, address and phone number (if available). The request for this report can also
            include crop years with old or uncalculated metrics, though this is not recommended for reporting use. Only
            finalize and metrics calculated using the latest version are recommended for reporting. The Fieldprint
            Project Reports use the data from this spreadsheet to generate tables and graphs that give insights into the
            project’s sustainability.
        </Typography>
    ),
    html: (
        <Typography gutterBottom>
            Using the information provided in the Comprehensive Data Output Files, these reports serve as an essential
            resource for analyzing and understanding the sustainability performance of agricultural operations. Key
            Components of the report include:
            <ul>
                <li>
                    Project Summary information such as enrolled area, number of fields, grower retention rates, and
                    detailed project maps providing a foundational overview of the project scope.
                </li>
                <li>
                    Insights into Project Performance based on Field to Market's 8 sustainability metrics, including
                    distribution boxplots and summary tables illustrate trends and variability; production- and
                    area-weighted averages offer a holistic understanding of performance, including detailed component
                    breakdowns for energy use and greenhouse gas (GHG) emissions.
                </li>
            </ul>
            Fieldprint Project Reports empower stakeholders with a clear, data-driven understanding of their
            sustainability performance, helping identify areas for improvement, track progress, and communicate outcomes
            effectively.
        </Typography>
    ),
    quality: (
        <Typography gutterBottom>
            The Data Quality Control Check report provides an excel spreadsheet detailing grower ID, grower name, grower
            email, field name, field size, the county location of the field, the crop and crop year for each field
            enrolled in the project.
        </Typography>
    ),
    participation: (
        <Typography gutterBottom>
            The Participation Data Output File report provides an excel spreadsheet detailing grower ID, grower name,
            grower email, field name, field size, the county location of the field, the crop and crop year for each
            field enrolled in the project.
        </Typography>
    ),
    nbalance: (
        <Typography gutterBottom>
            The Fieldprint Platform now incorporates Environmental Defense Fund’s N Balance Model for Fieldprint
            Projects.{" "}
            <a
                href="https://www.edf.org/ecosystems/making-invisible-loss-nitrogen-visible-farm-and-future"
                target="_blank"
                rel="noopener noreferrer"
            >
                Environmental Defense Fund’s N Balance Model
            </a>{" "}
            is designed to help farmers and agricultural stakeholders optimize nitrogen use efficiency. It balances
            nitrogen inputs, such as fertilizers, with outputs, including crop yields, to minimize nitrogen losses to
            the environment. By doing so, it helps reduce water pollution, greenhouse gas emissions, and other
            environmental impacts associated with excessive nitrogen use, promoting more sustainable and environmentally
            friendly agricultural practices. N Balance is available for projects where at least one grower with at least
            one field connected to the project has completed the Supplemental Questionnaire for N Balance with their
            farm.
        </Typography>
    ),
    sai: (
        <>
            <Typography gutterBottom>
                Field to Market: The Alliance for Sustainable Agriculture and the Sustainable Agriculture Initiative
                (SAI) Platform maintain an equivalency agreement, which formally recognizes Field to Market's Fieldprint
                Platform as an accepted means of fulfilling the requirements of SAI Platform’s Farm Sustainability
                Assessment (FSA). For more information, visit the{" "}
                <a
                    href="https://fieldtomarket.org/field-to-market-and-sai-platform-announce-first-use-of-joint-equivalency-module-by-leading-food-companies/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Field to Market SAI Platform Equivalency Agreement page
                </a>
                .
            </Typography>
            <Typography gutterBottom>
                Reports are available for years where at least one grower with at least one field connected to Project
                has completed the Supplemental Questionnaire for Equivalency with SAI Platform FSA for their farm.
                Project Administrators are responsible for outreach to growers who have been selected to complete the
                questionnaire.
            </Typography>
        </>
    ),
    tsc: (
        <>
            <Typography gutterBottom>
                Field to Market and The Sustainability Consortium (TSC) are working to harmonize measurement and
                reporting of sustainable agriculture and encourage data platform interoperability. Field to Market's
                Fieldprint Platform and Supply Chain Sustainability Program are approved by TSC collectively as a Ready
                to Use tool and standard for commodity sourcing from farms in the United States.
            </Typography>
            <Typography gutterBottom>
                Companies sourcing commodities through Projects can generate aggregate field-level data results from the
                Fieldprint Platform and report into TSC for{" "}
                <a
                    href="https://fieldtomarket.org/members/resource/tsc-alignment-matrix/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    33 product categories and 21 Key Performance Indicators
                </a>
                . For instructions on how to utilize data from the Fieldprint Platform or Field to Market Projects to
                report into TSC, please{" "}
                <a
                    href="https://fieldtomarket.org/members/resource/guidance-responding-tscs-key-performance-indicators/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    download our joint guidance
                </a>
                .
            </Typography>
        </>
    ),
    benchmarks: (
        <Typography gutterBottom>
            The Project Benchmarks report computes and returns the latest benchmarks for the selected crop and
            irrigation type, based on weighted average of the finalized metric values. At least 10 unique growers per
            crop and irrigation type are required to generate benchmarks.
        </Typography>
    ),
    shapefile: (
        <Typography gutterBottom>
            The Project Fields Shapefile is a zip file that provides all field boundaries enrolled in the project, which
            can be used for more advanced geographic analysis in ArcGIS or other such programs.
        </Typography>
    ),
    overview: (
        <Typography gutterBottom>
            The Project Overview Report provides an excel spreadsheet detailing the total number of growers and total
            number of acres enrolled in the project for a specific crop year.
        </Typography>
    ),

    stats: (
        <Typography gutterBottom>
            The Project Stats report provides an excel spreadsheet with the number of growers and entered and enrolled
            acres for each crop type for each crop year.
        </Typography>
    ),
};

Reports = connect(
    (state, ownProps) => ({
        project: getProject(state, ownProps),
        authState: state.auth,
    }),
    {
        ...authActions,
        reload: reloadAll,
    },
)(Reports);

export default withStyles(styles)(withRouter(Reports));
