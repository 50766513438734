import React from "react";
import TextField from "./TextField";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";

// FIXME: If a menu item with a value of 0 is selected the item doesn't appear to be selected
// https://github.com/mui-org/material-ui/issues/10531
// https://github.com/mui-org/material-ui/issues/8990

//SelectProps={{displayEmpty:true}}
//SelectProps={{...rest}}
export default function Select({ native, options, ...rest }) {
    if (options.some(({ optgroup }) => optgroup) && !native) {
        options = flattenOptions(options);
    }

    return (
        <TextField select {...rest}>
            {native &&
                options.map(({ label, value, optgroup, items }) => {
                    if (optgroup) {
                        return (
                            <optgroup label={label} key={label}>
                                {items.map(({ iLabel, iValue }) => (
                                    <option key={iValue} value={iValue}>
                                        {iLabel}
                                    </option>
                                ))}
                            </optgroup>
                        );
                    } else {
                        return (
                            <option value={value} key={value}>
                                {label}
                            </option>
                        );
                    }
                })}
            {!native &&
                options.map(({ label, value, optgroup }) =>
                    optgroup ? (
                        <ListSubheader
                            key={label}
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            {label}
                        </ListSubheader>
                    ) : (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ),
                )}
        </TextField>
    );
}

function flattenOptions(options) {
    const flatOptions = [];
    for (const { label, value, optgroup, items } of options) {
        if (optgroup) {
            flatOptions.push({ optgroup: true, label });
            for (const { iLabel, iValue } of items) {
                flatOptions.push({
                    label: iLabel,
                    value: iValue,
                });
            }
        } else {
            flatOptions.push({ label, value });
        }
    }
    return flatOptions;
}
